
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index7iQGChwHgbMeta } from "/home/archerist/nuxt3_ogrenci/pages/archive/index.vue?macro=true";
import { default as emailRHvttC81dDMeta } from "/home/archerist/nuxt3_ogrenci/pages/email.vue?macro=true";
import { default as forcepasschangetkch8NHalwMeta } from "/home/archerist/nuxt3_ogrenci/pages/forcepasschange.vue?macro=true";
import { default as index5ZKm5NyU2vMeta } from "/home/archerist/nuxt3_ogrenci/pages/index.vue?macro=true";
import { default as citiessS1I4TuV3cMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/cities.vue?macro=true";
import { default as daterangeAFMYrUBmDoMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/daterange.vue?macro=true";
import { default as fakulte25kpe0amZLMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/fakulte.vue?macro=true";
import { default as indexSWor3B3UdCMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/index.vue?macro=true";
import { default as newmemberskjEu9Tb0MlMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/newmembers.vue?macro=true";
import { default as ogrenciseviyeuZzsGCiw0fMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/ogrenciseviye.vue?macro=true";
import { default as periodicuBq0aOD9GAMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/periodic.vue?macro=true";
import { default as universities9WSdtKFM1SMeta } from "/home/archerist/nuxt3_ogrenci/pages/reports/universities.vue?macro=true";
import { default as index2W65FC2YpEMeta } from "/home/archerist/nuxt3_ogrenci/pages/settings/index.vue?macro=true";
import { default as emails8x9134OstLMeta } from "/home/archerist/nuxt3_ogrenci/pages/students/[username]/emails.vue?macro=true";
import { default as familyXeV56BVwboMeta } from "/home/archerist/nuxt3_ogrenci/pages/students/[username]/family.vue?macro=true";
import { default as idl3FQk4TWw3Meta } from "/home/archerist/nuxt3_ogrenci/pages/students/[username]/id.vue?macro=true";
import { default as legalSffhECncvTMeta } from "/home/archerist/nuxt3_ogrenci/pages/students/[username]/legal.vue?macro=true";
import { default as paymentsFOdJmkUiNbMeta } from "/home/archerist/nuxt3_ogrenci/pages/students/[username]/payments.vue?macro=true";
import { default as school8pgorNXdpPMeta } from "/home/archerist/nuxt3_ogrenci/pages/students/[username]/school.vue?macro=true";
import { default as settingsZ63T3ewUb3Meta } from "/home/archerist/nuxt3_ogrenci/pages/students/[username]/settings.vue?macro=true";
import { default as indexqMOHZ4lm6KMeta } from "/home/archerist/nuxt3_ogrenci/pages/students/index.vue?macro=true";
export default [
  {
    name: "archive",
    path: "/archive",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/archive/index.vue")
  },
  {
    name: "email",
    path: "/email",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/email.vue")
  },
  {
    name: "forcepasschange",
    path: "/forcepasschange",
    meta: forcepasschangetkch8NHalwMeta || {},
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/forcepasschange.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index5ZKm5NyU2vMeta || {},
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/index.vue")
  },
  {
    name: "reports-cities",
    path: "/reports/cities",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/cities.vue")
  },
  {
    name: "reports-daterange",
    path: "/reports/daterange",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/daterange.vue")
  },
  {
    name: "reports-fakulte",
    path: "/reports/fakulte",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/fakulte.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/index.vue")
  },
  {
    name: "reports-newmembers",
    path: "/reports/newmembers",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/newmembers.vue")
  },
  {
    name: "reports-ogrenciseviye",
    path: "/reports/ogrenciseviye",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/ogrenciseviye.vue")
  },
  {
    name: "reports-periodic",
    path: "/reports/periodic",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/periodic.vue")
  },
  {
    name: "reports-universities",
    path: "/reports/universities",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/reports/universities.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/settings/index.vue")
  },
  {
    name: "students-username-emails",
    path: "/students/:username()/emails",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/[username]/emails.vue")
  },
  {
    name: "students-username-family",
    path: "/students/:username()/family",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/[username]/family.vue")
  },
  {
    name: "students-username-id",
    path: "/students/:username()/id",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/[username]/id.vue")
  },
  {
    name: "students-username-legal",
    path: "/students/:username()/legal",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/[username]/legal.vue")
  },
  {
    name: "students-username-payments",
    path: "/students/:username()/payments",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/[username]/payments.vue")
  },
  {
    name: "students-username-school",
    path: "/students/:username()/school",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/[username]/school.vue")
  },
  {
    name: "students-username-settings",
    path: "/students/:username()/settings",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/[username]/settings.vue")
  },
  {
    name: "students",
    path: "/students",
    component: () => import("/home/archerist/nuxt3_ogrenci/pages/students/index.vue")
  }
]